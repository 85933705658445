<template>
  <div>
    <div class="flex-def flex-zEnd">
      <el-button @click="$router.back()" type="text">返回订单列表</el-button>
    </div>
    <div class="y-desc" style="color: red;margin-bottom: 1rem">
      订单编号：{{ orderID }}<br>
      购买用户：#{{ticket_grant.uid}}:{{ticket_grant.nickname}}<br>
      奖励次数：{{ticket_grant.num}}
    </div>
    <el-table :data="award_grant_list">
      <el-table-column prop="id" label="记录编号"></el-table-column>
      <el-table-column prop="grant_id" label="分红编号"></el-table-column>
      <el-table-column prop="uid" label="用户编号"></el-table-column>
      <el-table-column prop="nickname" label="用户昵称"></el-table-column>
      <el-table-column prop="fee" label="分红金额"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "orderAwardDetail",
  data(){
    return{
      orderID :0,
      ticket_grant:{
        uid:0,
        num:0,
        nickname:"",
      },
      award_grant_list:[]
    }
  },
  mounted() {
    this.orderID = parseInt(this.$route.query.order_id);
    this.load();
  },
  methods:{
    load(){
      this.$u.api.shop.plugin.blindAward.orderAwardDetail({
        id:this.orderID
      }).then(res=>{
        this.ticket_grant = res.ticket_grant;
        this.award_grant_list = res.award_grant_list;
      })
    }
  }
}
</script>

<style scoped>

</style>